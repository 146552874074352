@charset "UTF-8";
.modal-header {
  border: 0px !important;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
}
.modal-header h5 {
  width: 100%;
  text-align: center;
}
.modal-header .btn-close {
  color: white;
  background: none;
}
.modal-header .btn-close:before {
  content: "✕";
  display: inline-block;
  color: white;
  position: absolute;
  font-size: 20px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal-content {
  background: transparent !important;
  border-radius: 40px !important;
}

.modal-body {
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 40px;
}
.modal-body .form-outline .form-notch {
  display: none;
}
.modal-body .form-outline .form-label,
.modal-body .form-outline input {
  color: #FFFFFF;
}
.modal-body .form-outline input.form-control {
  padding: 10px 12px !important;
}
.modal-body .form-outline .form-control:focus ~ .form-label {
  color: #FFFFFF;
}
.modal-body .form-outline:after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background: #FFFFFF;
  position: absolute;
  bottom: 0px;
  left: 0;
}
.modal-body .submit-button {
  color: #FFFFFF;
  background: transparent !important;
  border: 1px solid #FFFFFF !important;
}

/* Change autocomplete styles in WebKit */
.form-outline input:-webkit-autofill,
.form-outline input:-webkit-autofill:hover,
.form-outline input:-webkit-autofill:focus,
.form-outline input:-webkit-autofill:active,
.form-outline textarea:-webkit-autofill,
.form-outline textarea:-webkit-autofill:hover,
.form-outline textarea:-webkit-autofill:focus,
.form-outline textarea:-webkit-autofill:active,
.form-outline select:-webkit-autofill,
.form-outline select:-webkit-autofill:focus,
.form-outline select:-webkit-autofill:active {
  color: #FFFFFF !important;
  -webkit-text-fill-color: #FFFFFF !important;
  -webkit-box-shadow: 0 0 0px 1000px #3E3B5E inset !important;
  background: #3E3B5E !important;
  transition: background-color 5000s ease-in-out 0s !important;
}