.bg-dark {
  background-color: rgba(62, 59, 94, var(--mdb-bg-opacity)) !important;
}

.bg-black {
  background-color: rgba(44, 42, 63, var(--mdb-bg-opacity)) !important;
}

@media (min-width: 1400px) {
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1410px;
  }
}
