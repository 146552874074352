.MuiDataGrid-root {
    border: 0px !important;
}

button.toolbar-button  {
    color: white !important;

    &:disabled {
        color: #534F7D !important;
    }

    .MuiButton-startIcon, svg {
        width: 20px;
        height: 20px;
    }
}

.toolbar-search {
    .MuiInput-root {
        color: #FFFFFF;

        &:after,
        &:before {
            border-color: #FFFFFF !important;
        }
    }
}

.MuiDataGrid-toolbarContainer {
    margin-bottom: 26px;
}

.MuiDataGrid-columnHeaders {
    background-color:rgba(62, 59, 94, var(--mdb-bg-opacity)) !important;
    border-color: rgba(255, 255, 255, 0.2) !important;
    color: #FFFFFF;
    border-radius: 10px 10px 0px 0px !important;

    .MuiDataGrid-columnHeader {
        border-right: 1px solid rgba(255, 255, 255, 0.2) !important; 

        &:last-child {
            border-right: 1px solid transparent!important;
        }

        .MuiDataGrid-iconSeparator {
            display: none !important;
        }
    }
}

.MuiDataGrid-virtualScroller {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.MuiDataGrid-virtualScrollerRenderZone {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    .MuiDataGrid-row {
        &:last-child {
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
        
            .MuiDataGrid-cell {
                border-bottom: 0px !important;
            }
        }

        .MuiDataGrid-cell {
            color: #FFFFFF;
            border-color: rgba(255, 255, 255, 0.2) !important;
        }

        &:nth-child(even) {
           background-color:rgba(62, 59, 94, var(--mdb-bg-opacity)) !important; 
        }
    }
}

.MuiDataGrid-footerContainer {
    border: 0px !important;
    border-right: 1px solid rgba(255, 255, 255, 0.2) !important; 
    border-left: 1px solid rgba(255, 255, 255, 0.2) !important; 
    border-bottom: 1px solid rgba(255, 255, 255, 0.2) !important; 
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    margin-bottom: 70px;
    background-color:rgba(62, 59, 94, var(--mdb-bg-opacity)) !important;

    .MuiTablePagination-displayedRows,
    .MuiTablePagination-selectLabel {
        margin-bottom: 0px;
        color: #FFFFFF;
    }

    .MuiButtonBase-root {
        .MuiButtonBase-root {
            svg {
                color: #FFFFFF;
            }

            &:disabled {
                svg {
                    color: #534F7D !important;
                }
            }
        }
    }

    .MuiTablePagination-select {
        border: 1px solid #FFFFFF;
        border-radius: 3px;
        color: #FFFFFF;
    }

    .MuiTablePagination-selectIcon {
        color: #FFFFFF;
    }
}

.MuiCheckbox-root {
    svg {
        color: #FFFFFF;
    }
}

.MuiDataGrid-main {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.2) !important; 
}

.candidateModal {
    color: red !important;
}

.MuiBox-root {
    .MuiButtonBase-root {
        color: white;

        svg {
            color: white;
            fill: white;
        }
    }
}

.input-white {
    fieldset {
        border-color: white;
    }

    &:hover {
        fieldset {
            border-color: white !important;
        }
    }

    svg,
    .MuiSelect-select,
    select,
    label,
    input {
        color: white;
    }
}

.Mui-focused {
    color: white !important;

    fieldset {
        border-color: white !important;
    }

    svg,
    .MuiSelect-select,
    select,
    label,
    input {
        color: white !important;
    }
}

.btn-warning {
    color:#FFF;
    background-color:#F66F5D;
    border-color:#F66F5D;
}

.btn-warning:focus,
.btn-warning:active,
.btn-warning:hover {
    color:#FFF;
    background-color:#d26253;
    border-color:#d26253;
}