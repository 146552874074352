body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,
body,
#root {
  height: 100%;
}

.modal-backdrop {
  z-index: 1000 !important;
}

main {
  min-height: 94%;
  max-height: 94%;
}
.bg-dark {
  background-color: rgba(62, 59, 94, var(--mdb-bg-opacity)) !important;
}

.bg-black {
  background-color: rgba(44, 42, 63, var(--mdb-bg-opacity)) !important;
}

@media (min-width: 1400px) {
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1410px;
  }
}
.MuiDataGrid-root {
  border: 0px !important;
}

button.toolbar-button {
  color: white !important;
}
button.toolbar-button:disabled {
  color: #534F7D !important;
}
button.toolbar-button .MuiButton-startIcon, button.toolbar-button svg {
  width: 20px;
  height: 20px;
}

.toolbar-search .MuiInput-root {
  color: #FFFFFF;
}
.toolbar-search .MuiInput-root:after, .toolbar-search .MuiInput-root:before {
  border-color: #FFFFFF !important;
}

.MuiDataGrid-toolbarContainer {
  margin-bottom: 26px;
}

.MuiDataGrid-columnHeaders {
  background-color: rgba(62, 59, 94, var(--mdb-bg-opacity)) !important;
  border-color: rgba(255, 255, 255, 0.2) !important;
  color: #FFFFFF;
  border-radius: 10px 10px 0px 0px !important;
}
.MuiDataGrid-columnHeaders .MuiDataGrid-columnHeader {
  border-right: 1px solid rgba(255, 255, 255, 0.2) !important;
}
.MuiDataGrid-columnHeaders .MuiDataGrid-columnHeader:last-child {
  border-right: 1px solid transparent !important;
}
.MuiDataGrid-columnHeaders .MuiDataGrid-columnHeader .MuiDataGrid-iconSeparator {
  display: none !important;
}

.MuiDataGrid-virtualScroller {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.MuiDataGrid-virtualScrollerRenderZone {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row:last-child {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row:last-child .MuiDataGrid-cell {
  border-bottom: 0px !important;
}
.MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell {
  color: #FFFFFF;
  border-color: rgba(255, 255, 255, 0.2) !important;
}
.MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row:nth-child(even) {
  background-color: rgba(62, 59, 94, var(--mdb-bg-opacity)) !important;
}

.MuiDataGrid-footerContainer {
  border: 0px !important;
  border-right: 1px solid rgba(255, 255, 255, 0.2) !important;
  border-left: 1px solid rgba(255, 255, 255, 0.2) !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2) !important;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  margin-bottom: 70px;
  background-color: rgba(62, 59, 94, var(--mdb-bg-opacity)) !important;
}
.MuiDataGrid-footerContainer .MuiTablePagination-displayedRows,
.MuiDataGrid-footerContainer .MuiTablePagination-selectLabel {
  margin-bottom: 0px;
  color: #FFFFFF;
}
.MuiDataGrid-footerContainer .MuiButtonBase-root .MuiButtonBase-root svg {
  color: #FFFFFF;
}
.MuiDataGrid-footerContainer .MuiButtonBase-root .MuiButtonBase-root:disabled svg {
  color: #534F7D !important;
}
.MuiDataGrid-footerContainer .MuiTablePagination-select {
  border: 1px solid #FFFFFF;
  border-radius: 3px;
  color: #FFFFFF;
}
.MuiDataGrid-footerContainer .MuiTablePagination-selectIcon {
  color: #FFFFFF;
}

.MuiCheckbox-root svg {
  color: #FFFFFF;
}

.MuiDataGrid-main {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.2) !important;
}

.candidateModal {
  color: red !important;
}

.MuiBox-root .MuiButtonBase-root {
  color: white;
}
.MuiBox-root .MuiButtonBase-root svg {
  color: white;
  fill: white;
}

.input-white fieldset {
  border-color: white;
}
.input-white:hover fieldset {
  border-color: white !important;
}
.input-white svg,
.input-white .MuiSelect-select,
.input-white select,
.input-white label,
.input-white input {
  color: white;
}

.Mui-focused {
  color: white !important;
}
.Mui-focused fieldset {
  border-color: white !important;
}
.Mui-focused svg,
.Mui-focused .MuiSelect-select,
.Mui-focused select,
.Mui-focused label,
.Mui-focused input {
  color: white !important;
}

.btn-warning {
  color: #FFF;
  background-color: #F66F5D;
  border-color: #F66F5D;
}

.btn-warning:focus,
.btn-warning:active,
.btn-warning:hover {
  color: #FFF;
  background-color: #d26253;
  border-color: #d26253;
}
@charset "UTF-8";
.modal-header {
  border: 0px !important;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
}
.modal-header h5 {
  width: 100%;
  text-align: center;
}
.modal-header .btn-close {
  color: white;
  background: none;
}
.modal-header .btn-close:before {
  content: "\2715";
  display: inline-block;
  color: white;
  position: absolute;
  font-size: 20px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.modal-content {
  background: transparent !important;
  border-radius: 40px !important;
}

.modal-body {
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 40px;
}
.modal-body .form-outline .form-notch {
  display: none;
}
.modal-body .form-outline .form-label,
.modal-body .form-outline input {
  color: #FFFFFF;
}
.modal-body .form-outline input.form-control {
  padding: 10px 12px !important;
}
.modal-body .form-outline .form-control:focus ~ .form-label {
  color: #FFFFFF;
}
.modal-body .form-outline:after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background: #FFFFFF;
  position: absolute;
  bottom: 0px;
  left: 0;
}
.modal-body .submit-button {
  color: #FFFFFF;
  background: transparent !important;
  border: 1px solid #FFFFFF !important;
}

/* Change autocomplete styles in WebKit */
.form-outline input:-webkit-autofill,
.form-outline input:-webkit-autofill:hover,
.form-outline input:-webkit-autofill:focus,
.form-outline input:-webkit-autofill:active,
.form-outline textarea:-webkit-autofill,
.form-outline textarea:-webkit-autofill:hover,
.form-outline textarea:-webkit-autofill:focus,
.form-outline textarea:-webkit-autofill:active,
.form-outline select:-webkit-autofill,
.form-outline select:-webkit-autofill:focus,
.form-outline select:-webkit-autofill:active {
  color: #FFFFFF !important;
  -webkit-text-fill-color: #FFFFFF !important;
  -webkit-box-shadow: 0 0 0px 1000px #3E3B5E inset !important;
  background: #3E3B5E !important;
  -webkit-transition: background-color 5000s ease-in-out 0s !important;
  transition: background-color 5000s ease-in-out 0s !important;
}
